import { request } from './request'
import qs from 'qs'

// 角色的权限 rbac/role-permission
export function getRolePower (params) {
  return request({
    url: '/admin/rbac/role-permission',
    method: 'GET',
    params
  })
}

//更新角色权限
export function metRolePower (datas) {
  return request({
    url: '/admin/rbac/role-permission',
    method: 'POST',
    data: qs.stringify(datas)
  })
}

// 角色的权限列表
export function getRoleList (params) {
  return request({
    url: '/admin/rbac/role',
    method: 'GET',
    params
  })
}

// 角色 POST PUT DELETE
export function metRoleList (method, datas) {
  return request({
    url: '/admin/rbac/role',
    method,
    data: qs.stringify(datas)
  })
}

// 权限组
export function metMenuList (method, datas) {
  return request({
    url: '/admin/rbac/permission-group',
    method,
    data: qs.stringify(datas)
  })
}

// 获取 权限列表
export function getPowerData (params) {
  return request({
    url: '/admin/rbac/permission',
    method: 'GET',
    params
  })
}

// 权限操作（增加，修改）
export function methPowerData (method, datas) {
  return request({
    url: '/admin/rbac/permission',
    method,
    data: qs.stringify(datas)
  })
}

// 用户列表
export function getUserList (params) {
  return request({
    url: '/admin/admin-user',
    method: 'GET',
    params
  })
}

// 用户列表中角色设置
export function getUserRole (params) {
  return request({
    url: '/admin/admin-user/role-permission',
    method: 'GET',
    params
  })
}

// 用户 添加 修改
export function methUserData (method, datas) {
  return request({
    url: '/admin/admin-user',
    method,
    data: qs.stringify(datas)
  })
}
// 用户 状态更新
export function postUserStateData (datas) {
  return request({
    url: '/admin/admin-user/change-status',
    method: 'POST',
    data: qs.stringify(datas)
  })
}
// 用户 关联的 角色
export function getUserOfRoleData (params) {
  return request({
    url: '/admin/admin-user/role-permission',
    method: 'GET',
    params
  })
}
// 用户 授权 角色
export function postUserAuthorizeRoleData (datas) {
  return request({
    url: '/admin/admin-user/role-permission',
    method: 'POST',
    data: qs.stringify(datas)
  })
}

// 获取供应商列表
export function getSuppliperList (params) {
  return request({
    url: '/admin/supplier',
    method: 'GET',
    params
  })
}

// 供应商 添加 修改
export function methSuppliperData (method, datas) {
  return request({
    url: 'admin/supplier',
    method,
    data: qs.stringify(datas)
  })
}
// 获取供应商详细列表
export function getSuppliperListDetail (params) {
  return request({
    url: 'admin/supplier/show',
    method: 'GET',
    params
  })
}

//审核供应商
export function AuditSupplier (datas){
  return request({
    url:'admin/supplier/check',
    method:'PUT',
    data: qs.stringify(datas)
  })
}

//更新状态
export function changeSupplierStatus (datas){
  return request({
    url:'admin/supplier/change-status',
    method:'PUT',
    data: qs.stringify(datas)
  })
}

// 获取供应商流水
export function getSupplierStream (params) {
  return request({
    url: '/admin/supplier/recordList',
    method: 'GET',
    params
  })
}

//-------------------- 数据代理商---------- /
// 获取数据代理商列表
export function getDataAgentList (params) {
  return request({
    url: '/admin/data-agent',
    method: 'GET',
    params
  })
}


// 数据代理商 添加 修改
export function methDataAgent (method, datas) {
  return request({
    url: 'admin/data-agent',
    method,
    data: qs.stringify(datas)
  })
}

// 数据代理商 详情
export function getDataAgentDetail (params) {
  return request({
    url: 'admin/data-agent/show',
    method:'GET',
    params
  })
}

//审核数据代理商
export function AuditDataAgent (datas){
  return request({
    url:'admin/data-agent/check',
    method:'PUT',
    data: qs.stringify(datas)
  })
}

//更新状态
export function changeDataAgent (datas){
  return request({
    url:'admin/data-agent/change-status',
    method:'PUT',
    data: qs.stringify(datas)
  })
}

// 获取数据代理商流水
export function getDataAgentStream (params) {
  return request({
    url: '/admin/data-agent/recordList',
    method: 'GET',
    params
  })
}

// 获取数据代理商余额变动列表
export function getDataAgentChangeList (params) {
  return request({
    url: '/admin/data-agent-change',
    method: 'GET',
    params
  })
}

// 数据代理商余额变动 添加 修改
export function methDataAgentChange (method, datas) {
  return request({
    url: 'admin/data-agent-change',
    method,
    data: qs.stringify(datas)
  })
}

//审核数据代理商余额变动
export function streamAudit (datas){
  return request({
    url:'admin/data-agent-change',
    method:'PUT',
    data: qs.stringify(datas)
  })
}
