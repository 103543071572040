<template>
  <div class="contentData">
    <!-- Dialog 对话框 -->
    <el-dialog :title="openType === 'add' ? '新增权限菜单' : '编辑权限菜单'"
               :visible.sync="isShowDialogVisible">
      <CommonForm :form="openForm"
                  :formLabel="openFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="cancelPowerForm">取 消</el-button>
        <el-button type="primary"
                   @click="subPowerForm(openType, openForm)">确 定</el-button>
      </div>
    </el-dialog>

    <CommonManageMain class="CommonManageMain">
      <template v-slot:header>
        <div>
          <el-button type="primary"
                     size="small"
                     @click="addPowerForm()"
                     icon="el-icon-circle-plus-outline">添加</el-button>
          <!-- <el-button type="danger" size="small" icon="el-icon-delete" @click="delPowerGroup()">删除</el-button> -->
        </div>

        <CommonForm :form="searchForm"
                    :formLabel="formLabel"
                    @blurInput="blurInput"
                    inline>
          <el-button type="primary"
                     @click="searchFormName(searchForm.keyword)">搜索</el-button>
        </CommonForm>
      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <!-- 组织架构 权限 -->
        <el-table :data="tableData"
                  :row-key="getRowKeys"
                  style="width: 100%"
                  border
                  :select-on-indeterminate="false"
                  default-expand-all
                  :tree-props="{ children: 'children', hasChildren: true }"
                  :highlight-current-row="true">
          <el-table-column sortable
                           label="菜单列表名称"
                           width="200"
                           :reserve-selection="true">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{ scope.row.name }}</span>
            </template>
          </el-table-column>

          <el-table-column prop="sort"
                           label="排序"
                           align="center"
                           :reserve-selection="true"></el-table-column>

          <el-table-column label="图标"
                           align="center"
                           :reserve-selection="true">
            <template slot-scope="scope">
              <el-image :src="scope.row.icon"
                        :alt="scope.row.icon"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="path"
                           label="路径"
                           align="center"
                           :reserve-selection="true"></el-table-column>
          <el-table-column prop="parent_id"
                           label="父级id"
                           align="center"
                           :reserve-selection="true"></el-table-column>
          <el-table-column prop="update_time"
                           label="更新时间"
                           align="center"
                           :reserve-selection="true"></el-table-column>
          <el-table-column prop="description"
                           label="备注"
                           align="center"
                           :reserve-selection="true"></el-table-column>

          <el-table-column label="操作"
                           align="center"
                           width="100">
            <template slot-scope="scope">
              <el-tooltip :enterable="false"
                          class="item"
                          effect="dark"
                          content="编辑"
                          placement="top">
                <el-button type="warning"
                           size="mini"
                           icon="el-icon-edit"
                           @click="clickEditGroup(scope.row)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import { metMenuList } from '@/api/organize.js'
import CommonForm from 'components/CommonForm.vue'
import CommonManageMain from 'components/CommonManageMain.vue'

export default {
  components: {
    CommonForm,
    CommonManageMain
  },

  data () {
    return {
      // 对话框 Dialog 基础数据
      openType: 'add',
      isShowDialogVisible: false,

      // form表单 基础数据
      openForm: {
        id: '',
        name: '',
        description: '',
        code: '',
        icon: '',
        path: '',
        parent_id: '',
        sort: '',
        update_time: ''
      },
      openFormLabel: [
        {
          model: 'id',
          label: 'ID'
        },
        {
          model: 'name',
          label: '权限组名'
        },
        {
          model: 'description',
          label: '备注'
        },
        {
          model: 'code',
          label: '唯一标识'
        },
        {
          model: 'icon',
          label: '图标名称'
        },
        {
          model: 'path',
          label: '路径'
        },
        {
          model: 'parent_id',
          label: '父级id'
          // type: 'select',
          // options: []
        },
        {
          model: 'sort',
          label: '排序'
        }
      ],

      // 表格数据
      tableData: [],
      // 全部数据
      allPowerData: [],
      // 表格所有的id数据
      tableIdList: [],
      // 搜索
      searchForm: {
        keyword: ''
      },
      formLabel: [
        {
          model: 'keyword',
          label: '名字',
          labelOFF: true,
          options: []
        }
      ]
    }
  },
  mounted () {
    this.getPowerGroupList()
  },
  methods: {
    // 获取权限组列表
    getPowerGroupList () {
      metMenuList().then(res => {
        console.log(res)
        this.allPowerData = res.data.data
        this.powerTableData()
      })
    },
    // 添加权限组--发送到后端
    addMethodPowerGroup (method, args) {
      metMenuList(method, args).then(res => {
        console.log(res)
        // 重新更新权限组列表
        this.getPowerGroupList()
      })
    },

    // 渲染table 数据 ======再优化========
    powerTableData () {
      this.tableData = []

      const datas = this.allPowerData
      datas.forEach(item => {
        const arr = []
        item.child.forEach(cItem => {
          this.tableIdList.push(cItem.id)
          arr.push({
            id: cItem.id,
            name: cItem.name,
            description: cItem.description,
            code: cItem.code,
            icon: cItem.icon,
            path: cItem.path,
            parent_id: cItem.parent_id,
            sort: cItem.sort,
            update_time: cItem.update_time
          })
        })
        this.tableIdList.push(item.id)
        this.tableData.push({
          id: item.id,
          name: item.name,
          description: item.description,
          code: item.code,
          icon: item.icon,
          path: item.path,
          parent_id: item.parent_id,
          sort: item.sort,
          update_time: item.update_time,
          children: arr
        })
      })
    },

    // 确定提交 权限组 --确定
    subPowerForm (type, val) {
      console.log(type, val)
      val.parent_id = Number(val.parent_id)
      val.sort = Number(val.sort)
      type == 'add'
        ? this.addMethodPowerGroup('POST', val)
        : this.addMethodPowerGroup('PUT', val)
      this.isShowDialogVisible = false
    },
    // 取消 添加权限组
    cancelPowerForm () {
      this.isShowDialogVisible = false
      // 重新更新权限组列表
      // this.getPowerGroupList()
    },

    // 添加按钮
    addPowerForm () {
      // 重置为空置
      Object.assign(this.openForm, {
        id: '',
        name: '',
        description: '',
        code: '',
        icon: '',
        path: '',
        parent_id: '',
        sort: '1',
        update_time: ''
      })
      this.openType = 'add'
      this.isShowDialogVisible = true
      // 默认 添加 id
      const maxId = Math.max(...this.tableIdList)
      this.openForm.id = maxId + 1
    },

    // 编辑权限组
    clickEditGroup (obj) {
      // id: '',
      // name: '',
      // description: '',
      // code: '',
      // icon: '',
      // path: '',
      // parent_id: '',
      // sort: '',
      // update_time: '',

      // 在表单中修改数据，表格的数据也跟着修改的问题 利用深拷贝
      this.openForm = JSON.parse(JSON.stringify(obj))
      this.isShowDialogVisible = true
      this.openType = 'edit'
    },
    // 列表的id
    getRowKeys (row) {
      return row.id
    },

    // 点击搜素按钮搜索列表内容
    searchFormName (row) {
      const list = this.tableData
      let id = -1
      for (let i = 0; i < list.length; i++) {
        if (row == list[i].name) {
          // console.log(list[i])
          id = list[i].id
        } else {
          for (let j = 0; j < list[i].children.length; j++) {
            if (row == list[i].children[j].name) {
              id = list[i].children[j].parent_id
            }
          }
        }
      }

      const tableList = list.filter(item => {
        // console.log(item.id)
        if (item.id == id) {
          return true
        }
      })
      this.tableData = tableList
    },

    // 失去焦点执行
    blurInput (val) {
      if (!val) {
        // 渲染table 数据
        this.powerTableData()
      }
    },

    unique (arr) {
      return arr.filter(function (item, index, arr) {
        // 当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
        return arr.indexOf(item, 0) === index
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.contentData {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .CommonManageMain {
    flex: 1;
  }
}
</style>
